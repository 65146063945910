import {
  Box,
  Button as ChakraButton,
  Flex,
  Grid,
  GridProps,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/core"
import React from "react"
import { bp } from "../../utils/MediaQueries"
import { Button } from "../Buttons"
import FormattedTitle from "../FormattedTitle"
import Notice from "../Notice"
import Body from "../typography/Body"
import Heading from "../typography/Heading"

export type CartItemProps = GridProps & {
  formattedTitle: string
  image: string
  imageMaxHeight?: string
  price: string
  details?: React.ReactNode
  customizations?: {
    image: string
    name: string
  }[]
  quantity: number
  quantityAvailable?: number
  matchPercent?: number
  isLoading?: boolean
  preorderLabel?: string
  onUpdateQuantity?: (q: number) => void // will need to provide for in-cart version
  onEditCustomizations?: VoidFunction
  testRideMessage?: string
}

const QUANTITY = new Array(10)

const CartItem: React.FC<CartItemProps> = ({
  formattedTitle,
  image,
  imageMaxHeight,
  price,
  details,
  quantity,
  quantityAvailable,
  matchPercent,
  customizations,
  isLoading,
  preorderLabel,
  onUpdateQuantity,
  onEditCustomizations,
  testRideMessage,
  ...props
}) => {
  return (
    <Grid
      backgroundColor="white"
      borderRadius="0.5rem"
      boxShadow="big"
      pt={bp("1.6638rem", "3.3125rem")}
      pb={bp("1.25rem", "3.375rem")}
      pr={bp("1.25rem", "2.3944rem")}
      pl={bp("1.25rem", "1.375rem")}
      gridTemplateAreas={[
        `
          "image title"
          "image title"
          "custom custom"
          "price price"
        `,
        null,
        null,
        null,
        `
          "image title price"
          "image title price"
          "image custom price"
        `,
      ]}
      columnGap="0.75rem"
      rowGap={bp("1rem", "0")}
      {...props}
    >
      <Flex
        gridArea="image"
        align="center"
        justify="center"
        direction="column"
        flex={2}
        maxWidth="21.875rem"
      >
        <Image
          width="100%"
          maxHeight={imageMaxHeight}
          objectFit={imageMaxHeight ? "contain" : undefined}
          px="1rem"
          src={image}
        />
        {matchPercent && (
          <Box
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            color="primary"
            fontWeight="normal"
            backgroundColor="rgba(0, 196, 209, 0.2)"
            px="0.6875rem"
            py="0.3125rem"
            borderRadius="0.5rem"
          >
            <Body size="xs" as="span">
              <b>{matchPercent}%</b> Match!
            </Body>
          </Box>
        )}
      </Flex>
      <Box gridArea="title" w={[null, null, null, null, "18.75rem"]}>
        <Heading as="span" display="block" size="5" mb="1.5625rem">
          <FormattedTitle raw={formattedTitle} />
        </Heading>
        {details && (
          <Body size="md" color="dawn" mb="1.0625rem">
            {details}
          </Body>
        )}
        {preorderLabel && (
          <Notice d={bp("none", "flex")} mb="1rem">
            {preorderLabel}
          </Notice>
        )}
      </Box>
      <Box gridArea="custom" maxW={[null, null, null, null, "18.75rem"]}>
        {preorderLabel && (
          <Notice d={bp("flex", "none")} mb="1rem">
            {preorderLabel}
          </Notice>
        )}
        {customizations &&
          customizations.map((item, index) => (
            <Flex
              key={index}
              border="1px solid"
              borderColor="dividerLine"
              borderRadius="0.5rem"
              py="0.25rem"
              pr="1rem"
              pl="0.75rem"
              mb="0.5325rem"
              align="center"
            >
              <Flex
                size="2.8125rem"
                align="center"
                justify="center"
                mr="0.6012rem"
              >
                <Image src={item.image} width="100%" />
              </Flex>
              <Body size="md" color="dawn">
                {item.name}
              </Body>
            </Flex>
          ))}
        {!!customizations?.length && (
          <Button
            w="100%"
            h="2.25rem"
            mt="0.7606rem"
            theme="secondary"
            fontSize="0.75rem"
            onClick={onEditCustomizations}
          >
            Edit Customizations
          </Button>
        )}
        {testRideMessage && (
          <Flex mt="1.25rem">
            <Icon
              name="testRide"
              color="primary"
              size="1.25rem"
              mr="0.5rem"
            />
            <Body color="primary">{testRideMessage}</Body>
          </Flex>
        )}
      </Box>
      <Flex
        gridArea="price"
        align={bp("center", "flex-end")}
        justify={bp("space-between", "flex-start")}
        direction={bp("row", "column")}
        flex={1}
      >
        <Body
          size="md"
          fontWeight="semibold"
          color="primary"
          mb={bp(null, "2rem")}
          order={bp(3, 1)}
        >
          {price}
        </Body>
        <Box
          mb={bp(null, "0.8438rem")}
          opacity={isLoading ? 0.5 : 1}
          order={bp(1, 2)}
        >
          <Menu>
            <MenuButton
              color="dusk"
              px="1rem"
              py="0.875rem"
              border="1px solid"
              borderColor="dividerLine"
              borderRadius="0.5rem"
              whiteSpace="nowrap"
            >
              Qty: {quantity} <Icon name="chevron-down" color="dawn" />
            </MenuButton>
            <MenuList>
              {Array.from(QUANTITY).map((el, index) => (
                <MenuItem
                  key={index}
                  onClick={() => onUpdateQuantity?.(index + 1)}
                  isDisabled={
                    isLoading ||
                    (!!quantityAvailable && index + 1 > quantityAvailable)
                  }
                >
                  {index + 1}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Box>
        <ChakraButton
          variant="link"
          onClick={() => onUpdateQuantity?.(0)}
          isLoading={isLoading}
          order={bp(2, 3)}
        >
          Delete
        </ChakraButton>
      </Flex>
    </Grid>
  )
}

export default CartItem
