import React from "react"
import { Flex, FlexProps } from "@chakra-ui/core"
import SmallCaps from "../typography/SmallCaps"

export type BadgeProps = FlexProps & {
  number: number
}

export default function Badge({ number, ...props }: BadgeProps) {
  return (
    <Flex
      align="center"
      justify="center"
      size="1.5rem"
      backgroundColor="primary"
      borderRadius="6.25rem"
      color="white"
      {...props}
    >
      <SmallCaps
        size="lg"
        fontWeight="bold"
        letterSpacing="-0.5px"
        whiteSpace="nowrap"
        lineHeight="0"
        transform={number >= 10 ? "translate(-1px)" : undefined}
      >
        {number}
      </SmallCaps>
    </Flex>
  )
}
