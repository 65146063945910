import React from "react"
import {
  Button,
  ButtonProps,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/core"
import Body from "../typography/Body"
import Heading from "../typography/Heading"
import Paragraph from "../typography/Paragraph"
import ModalCloseButton from "../Buttons/ModalCloseButton"

export type ModalButtonProps = Omit<ButtonProps, "children"> & {
  iconName: string
  text: string
  modalBody: string
  title: string
}

export default function ModalButton({
  iconName,
  text,
  modalBody,
  title,
  color = "dawn",
  borderColor = "dividerLine",
  ...props
}: ModalButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button
        h="4.5rem"
        py="1.125rem"
        justifyContent="center"
        border="0px solid"
        borderColor={borderColor}
        borderRadius="0.5rem"
        alignItems="center"
        variant="outline"
        flexDirection="column"
        minWidth="unset"
        onClick={onOpen}
        pl="0px"
        pr="0px"
        {...props}
      >
        <Icon name={iconName} size="2rem" color={color} />
        <Body color={color} fontSize={props?.fontSize ? props.fontSize : "1rem"} fontWeight="400" pl="0.5rem" pr="0.5rem" mt="0.5rem">
          {text}
        </Body>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          width={["80%", null, null, null, "40vw"]}
          maxWidth={["400px", null, null, null, "37rem"]}
          maxH={[null, null, null, null, "80vh"]}
          minH="18.75rem"
          padding={["1.5rem", null, null, null, "3.625rem"]}
          mx="0.5rem"
          textAlign="center"
          borderRadius="0.5rem"
        >
          <Icon
            name={iconName}
            size="6rem"
            color={color}
            w="100%"
            style={{"position": "absolute", "top": "-48px", "left": "0", "textAlign": "center"}}
          />
          <ModalCloseButton />
          <Heading
            size="6"
            w="100%"
            fontWeight="bold"
            fontSize="1.5rem"
            textAlign="center"
            letterSpacing="0rem"
            mb="1rem"
            mt={["2rem", null, null, null, "0rem"]}
          >
            {title}
          </Heading>
          <Paragraph fontSize="0.8rem" lineHeight="1.2rem" overflowY="auto">{modalBody}</Paragraph>
        </ModalContent>
      </Modal>
    </>
  )
}
