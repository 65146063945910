import { Checkbox, Flex, Image } from "@chakra-ui/core"
import R from "ramda"
import React from "react"
import { StringParam, useQueryParam } from "use-query-params"
import { Accessory } from "views/BicycleProductPage"
import useShopifyVariantPricing from "../../hooks/useShopifyVariantPricing"
import { ShopifyVariantPricing } from "../../shopify/graphql/queries"
import { bp } from "../../utils/MediaQueries"
import { sortMethods } from "../../utils/sort"
import { Button } from "../Buttons"
import Body from "../typography/Body"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../styles/css/cartPageAddon.css"

function CartPageAddons({
  cartAddons,
  onAccessoryChange,
  selectedAccessories,
  onAddToCart,
  isCartLoading,
}) {
  // Sort
  const [sortParam, setSort] = useQueryParam("sort", StringParam)
  const sort = sortParam ? sortParam : "BY_RELEVANCE"
  // Pricing
  const { pricingMap } = useShopifyVariantPricing(
    cartAddons && R.uniq(R.flatten(cartAddons?.map((item) => item?.shopifyId)))
  )

  const sliderConfig = {
    className: "center",
    infinite: false,
    centerPadding: "60px",
    slidesToShow: 2,
    speed: 500,
    rows: 2,
    variableWidth: false,
    slidesPerRow: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1420,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          accessibility: true,
          rows: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          accessibility: true,
          rows: 2,
          arrows: true,
        },
      },
    ],
  }

  function NextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          right: 0,
        }}
        onClick={onClick}
      />
    )
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          left: 0,
        }}
        onClick={onClick}
      />
    )
  }

  let availableCartAddons = cartAddons
    .map((item) => ({
      ...item,
      pricing:
        (pricingMap[item?.shopifyId ?? ""] as ShopifyVariantPricing) || null,
    }))
    .sort(sortMethods[sort])
    .filter((item: Accessory) => item?.pricing?.availableForSale)

  if (availableCartAddons && availableCartAddons.length > 0) {
    return (
      <Flex
        flexDirection={"column"}
        flexWrap={bp("wrap", "nowrap")}
        justifyContent={"space-between"}
        style={{ gap: "15px" }}
        w="100%"
        p="0"
      >
        <Flex
          flexDirection={"row"}
          flexWrap={"nowrap"}
          justifyContent={"space-between"}
          alignContent="center"
          alignItems={"center"}
          style={{ gap: "15px" }}
          w="100%"
          pt={"20px"}
          pb={"15px"}
        >
          <Body
            // color="#777"
            fontWeight={500}
            px="10px"
            style={{ textTransform: "capitalize" }}
            fontSize={bp("14px", "16px")}
          >
            Recommended Add-ons
          </Body>
          {selectedAccessories.length > 0 ? (
            <Button
              onClick={onAddToCart}
              isLoading={isCartLoading}
              py="10px"
              px="17px"
              h={"auto"}
              w="auto"
              borderColor="primary"
              borderWidth="1px"
            >
              Add to Cart
            </Button>
          ) : (
            // Disabled
            <Button py="10px" px="17px" h={"auto"} w="auto" theme="tertiary">
              Add to Cart
            </Button>
          )}
        </Flex>
        <Slider {...sliderConfig}>
          {availableCartAddons &&
            availableCartAddons.map((item, key) => {
              return (
                <Flex
                  key={key}
                  w={bp("190px", "190px")}
                  maxW={bp("190px", "190px")}
                  flexDirection="column"
                  className="upsellProduct"
                  // borderWidth="1px"
                  // borderRadius="lg"
                  style={{ gap: "15px" }}
                >
                  <Flex
                    className="UpsellProductInfo"
                    w="100%"
                    flexDirection="column"
                    alignItems="center"
                    py="3px"
                    style={{ gap: "15px" }}
                    justifyContent="space-between"
                  >
                    <Checkbox
                      size="lg"
                      color="primary"
                      mx="10px"
                      my="5px"
                      onChange={(e) => {
                        onAccessoryChange(item)
                      }}
                      isChecked={selectedAccessories.find(
                        (acc: Accessory) =>
                          acc?.contentful_id === item?.contentful_id
                      )}
                    />
                    <Image
                      width="100%"
                      maxHeight={bp("60px", "80px")}
                      objectFit="contain"
                      bg="#fff"
                      p="3px"
                      src={item?.productListingImage?.fluid?.src}
                    />
                    <Flex
                      flexDirection="column"
                      alignContent={"center"}
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      <Flex
                        className="UpsellProductPrice"
                        w="100%"
                        flexDirection="row"
                        textAlign="center"
                        alignContent={"center"}
                        alignItems="center"
                        justifyContent={"center"}
                        color="primary"
                        fontWeight="bold"
                        lineHeight="1.125rem"
                        m={0}
                        p={0}
                        style={{ gap: "10px" }}
                        mb={"10px"}
                      >
                        {/* Actual Price */}
                        <span>
                          $
                          {item?.pricing?.priceV2?.amount
                            ? item?.pricing?.priceV2?.amount
                            : "FREE"}
                        </span>
                        {/* Del Price */}
                        {item?.pricing?.compareAtPriceV2?.amount && (
                          //@ts-ignore
                          <del
                            style={{
                              // color: "#999",
                              fontWeight: "normal",
                              fontSize: "14px",
                            }}
                          >
                            ${item?.pricing?.compareAtPriceV2?.amount}
                          </del>
                        )}
                      </Flex>
                      <Body
                        w="100%"
                        // color="#777"
                        fontWeight={500}
                        px="10px"
                        style={{ textTransform: "capitalize" }}
                        fontSize={bp("13px", "14px")}
                        textAlign="center"
                      >
                        {item?.accessory_model[0]?.title
                          ? item?.accessory_model[0]?.title
                          : item?.title}
                      </Body>
                    </Flex>
                  </Flex>
                </Flex>
              )
            })}
        </Slider>
      </Flex>
    )
  } else {
    return <div></div>
  }
}
export default CartPageAddons
